<template>
    <div class="view-content">
        <div class="top-content">
            <el-breadcrumb separator=">" class="breadcrumb">
                <el-breadcrumb-item>训练管理</el-breadcrumb-item>
                <el-breadcrumb-item
                        :to="{path:'/trainadmin/datacenter/studenttheorydetail',query:{train_id:trainInfo.train_id,user_id:trainInfo.user_id}}">
                    训练信息
                </el-breadcrumb-item>
                <el-breadcrumb-item>班级详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="student-name">学生：{{trainInfo.studentName}}</div>
            <div class="radio-content">
                <el-radio-group v-model="subjectType" size="medium" @change="selectType" class="radioGroup-purple">
                    <el-radio-button :label='subjectItem.id'
                                     v-for="(subjectItem, subjectIndex) in subjectTypeList"
                                     :key="`subjectTypeList_${subjectIndex}`">
                        {{subjectItem.name}}
                    </el-radio-button>
                </el-radio-group>
                <el-checkbox v-model="checked" @change="chooseCheck">只显示错题</el-checkbox>
            </div>
        </div>
        <el-scrollbar class="exerciseDetail-main" :native="false">
            <AnswerDetail :paperDetail="paperDetail"></AnswerDetail>
        </el-scrollbar>
    </div>
</template>

<script>
    import AnswerDetail from "../AnswerDetail";
    import {studentAnswerDetailTeacher} from '@/utils/apis'

    export default {
        name: "StudentAnswer",
        components: {
            AnswerDetail
        },
        data() {
            return {
                subjectType: 0, //题型
                checked: false,
                subjectTypeList: [
                    {
                        id: 0,
                        name: '全部'
                    },
                    {
                        id: 1,
                        name: '单选题'
                    },
                    {
                        id: 2,
                        name: '多选题'
                    },
                    {
                        id: 3,
                        name: '判断题'
                    },
                    {
                        id: 4,
                        name: '填空题'
                    },
                    {
                        id: 5,
                        name: '问答题'
                    },
                ],
                paperDetail: {},
                wrongTitleInfo: {},
                originalInfo: {},
                trainInfo: {}
            }
        },
        mounted() {
            let obj = {
                train_id: this.$route.query.train_id,
                times_id: this.$route.query.times_id,
                process_index: this.$route.query.process_index,
                process_page_total: this.$route.query.process_page_total,
                studentName: this.$route.query.studentName,
                user_id: this.$route.query.user_id,
            }
            this.trainInfo = obj
            this.getAnswerDetail()
        },
        methods: {
            selectType(val) {
                if (val === 1 || val === 0) {
                    document.querySelector('#questionType_single_data').scrollIntoView(true);
                } else if (val === 2) {
                    document.querySelector('#questionType_multiple_data').scrollIntoView(true);
                } else if (val === 3) {
                    document.querySelector('#questionType_judge_data').scrollIntoView(true);
                } else if (val === 4) {
                    document.querySelector('#questionType_blank_data').scrollIntoView(true);
                } else if (val === 5) {
                    document.querySelector('#questionType_answer_data').scrollIntoView(true);
                }
            },
            chooseCheck(val) {
                if (val) {
                    this.paperDetail = JSON.parse(JSON.stringify(this.wrongTitleInfo))
                } else {
                    this.paperDetail = JSON.parse(JSON.stringify(this.originalInfo))
                }
            },
            getAnswerDetail() {
                let params = {
                    page: this.trainInfo.process_index,
                    train_id: this.trainInfo.train_id,
                    times_id: this.trainInfo.times_id,
                    user_id: this.trainInfo.user_id
                }
                console.log(params)
                studentAnswerDetailTeacher(params).then(res => {
                    if (res.code == 200) {
                        this.originalInfo = res.data
                        for (let i in this.originalInfo) {
                            this.originalInfo[i].list.forEach(item => {
                                item.options = JSON.parse(item.options)
                                item.answer = JSON.parse(item.answer)
                                item.stu_answer = JSON.parse(item.stu_answer)
                                this.$set(item, 'isAnalysisShow', false)
                            })
                        }
                        this.paperDetail = JSON.parse(JSON.stringify(this.originalInfo))
                        //处理错题
                        let obj = JSON.parse(JSON.stringify(this.originalInfo))
                        for (let j in obj) {
                            obj[j].list = obj[j].list.filter(item => {
                                return item.is_true == 0
                            })
                        }
                        this.wrongTitleInfo = obj
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .view-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .exerciseDetail-main {
            height: 1%;
            flex: 1;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        .top-content {
            margin: 30px;

            ::v-deep .el-breadcrumb__item:last-child {

                .el-breadcrumb__inner {
                    color: #13131B;
                    font-weight: bold;
                }
            }

            .student-name {
                margin: 20px 0 30px;
                color: #13131B;
            }

            .radio-content {
                display: flex;
                align-items: center;
            }

            ::v-deep .el-checkbox {
                margin-left: 40px;

                .el-checkbox__inner {
                    height: 16px;
                    width: 16px;

                    &:hover {
                        border-color: #1122D8;
                    }
                }

                .el-checkbox__label {
                    font-size: 16px;
                }

                .el-checkbox__input.is-checked {

                    .el-checkbox__inner {
                        background-color: #1122D8;
                        border-color: #1122D8;

                        &::after {
                            height: 8px;
                            left: 5px;
                        }
                    }
                }

                .el-checkbox__input.is-checked + .el-checkbox__label {
                    color: #1122D8;
                    font-size: 16px;
                }
            }

            .radioGroup-purple {
                ::v-deep .el-radio-button {
                    margin-left: 16px;

                    .el-radio-button__inner {
                        color: #333;
                        border: 1px solid #BFBFBF;
                        border-radius: 4px;
                        padding: 10px 0;
                        width: 64px;
                        font-weight: normal;

                        &:hover {
                            color: #1122D8;
                        }
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    &:first-child, &:last-child {
                        .el-radio-button__inner {
                            border-radius: 0;
                        }
                    }

                    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                        color: #fff;
                        background-color: #1122D8;
                        border-color: #1122D8;
                        box-shadow: -1px 0 0 0 #1122D8;
                        border-radius: 4px;
                    }
                }
            }
        }

        ::v-deep .el-breadcrumb__item {
            .el-breadcrumb__inner.is-link {
                font-weight: 400;
                color: #606266;
            }
        }
    }
</style>